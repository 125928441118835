<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "Agregar nueva" : "Actualizar" }} escuela</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">

        <!-- IMAGE -->
        <template>

          <!-- Image Container -->
          <div class="img-container w-32 mx-auto flex items-center justify-center">
            <img v-if="dataImg" :src="dataImg" alt="img" class="responsive">
            <div v-else class="mr-8 rounded h-24 w-24 primary flex items-center flex-col justify-center" style="background-color: #f5f5f5"><feather-icon icon="ImageIcon" style="color:lightgray"></feather-icon></div>
            <span class="text-danger text-sm" v-show="errors.has('school_image')">{{ errors.first('school_image') }}</span>
          </div>

          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-center mt-5">
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*" name="school_image">
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Actualizar imágen</vs-button>
            <!-- <vs-button type="flat" color="#999" @click="dataImg = null">Eliminar imágen</vs-button> -->
          </div>
          <!-- <div v-show="image_error" class="vs-alert con-icon"><i class="vs-icon notranslate icon-scale icon-alert material-icons null">new_releases</i><span class="text-danger text-sm">{{ image_error }}</span></div> -->
          <vs-alert v-show="image_error" color="danger" style="height: initial" icon-pack="feather" icon="icon-info">
            {{ image_error }}
          </vs-alert>
        </template>

        <!-- NAME -->
        <vs-input label="Nombre de la Escuela" autocomplete="off" v-model="dataName" class="mt-5 w-full" name="item-name" v-validate="'required|max:40'" data-vv-as="Nombre de la Escuela"/>
        <span class="text-danger text-sm" v-show="errors.has('item-name')">{{ errors.first('item-name') }}</span>

        <div class="mt-5 w-full">
          <label class="vs-input--label">Comisión Tiptap</label>
          <div class="flex flex-wrap">
            <vs-input-number
              label="%"
              v-model="dataTiptapCommission"
              :step="0.125" name="tiptap_commission"
              v-validate="{ regex: /\d+(\.\d+)?$/, min_value: 0, max_value: 100, decimal: true }"
              class="w-100"
              @input="checkLength($event)"
              data-vv-as="Comisión Tiptap"
            />
            <span class="text-danger text-sm"  v-show="errors.has('tiptap_commission')">{{ errors.first('tiptap_commission') }}</span>
          </div>
        </div>

        <!-- OWNER -->
        <div class="mt-5">
          <label class="vs-input--label">Administrador de la escuela</label>
          <v-select browserAutocomplete="off" autocomplete="off" v-model="dataIdOwner" :reduce="dataIdOwner => dataIdOwner.value" :options="owner_choices" :clearable="false" v-validate="'required'" name="item-id_owner" :dir="$vs.rtl ? 'rtl' : 'ltr'" data-vv-as="Administrador de la escuela"/>
          <span class="text-danger text-sm" v-show="errors.has('item-owner')">{{ errors.first('item-id_owner') }}</span>
        </div>

        <!-- ADDRESS -->
        <vs-input label="Dirección" autocomplete="off" v-model="dataAddress" class="mt-5 w-full" name="item-address" v-validate="'required|max:40'" data-vv-as="Dirección"/>
        <span class="text-danger text-sm" v-show="errors.has('item-address')">{{ errors.first('item-address') }}</span>

        <div class="mt-5 w-full">
          <label class="vs-input--label">Comisión Tiptap</label>
          <div class="flex flex-wrap">
            <vs-input-number label="%" v-model="dataTiptapCommission" :step="0.125" name="tiptap_commission" class="w-100" data-vv-as="Comisión Tiptap"/>
            <span class="text-danger text-sm"  v-show="errors.has('tiptap_commission')">{{ errors.first('tiptap_commission') }}</span>
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid" id="saveButton">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        let { id, name, tiptap_commission, id_owner } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataName = name
        this.dataTiptapCommission = tiptap_commission
        this.dataIdOwner = id_owner
        this.owner = id_owner || null
        this.getOwnerChoices()
        this.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {
      dataImg: null,
      dataId: null,
      dataName: "",
      dataTiptapCommission: 0.000,
      dataIdOwner: null,
      owner: null,
      owner_choices: [],

      product_image: {
        size: '',
        width: '',
        height: ''
      },

      image_loaded: false,
      image_error: '',

      settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
      },

      dataAddress: '',

    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isFormValid() {
       return !this.errors.any() && this.dataName && this.dataIdOwner
    },

    //console: () => console,
  },
  methods: {
    initValues() {
      if(this.data.id) return
        this.dataId = null
        this.dataName = ""
        this.dataTiptapCommission = 0.000
        this.dataIdOwner = null
        this.getOwnerChoices()
    },
    submitData() {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.disableSaveButton();
            // If owner was changed, check if has pending payments
            // if (this.owner !== null && this.owner != this.dataIdOwner) {
            //   this.$store.dispatch(`dataListInstitutions/getPendingPayments`, this.owner)
            //     .then(response => {
            //       if(response.length) {
            //         let pending = response.filter(i => i.id_institution == this.dataId);
            //         if(pending.length && pending[0].total_pay != 0) {
            //           this.enableSaveButton();
            //           this.showError("Hay saldos pendientes de pago. Debe realizar la solicitud de pagos pendientes antes de realizar el cambio.");
            //         } else {
            //           this.addUpdateData();
            //         }
            //       } else {
            //         this.addUpdateData();
            //       }
            //     })
            //     .catch(error => {this.showError(error)});
            // } else {
            //   this.addUpdateData();
            // }
            this.addUpdateData();
          }
        })
    },
    disableSaveButton() {
      this.loading = true
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#saveButton",
        scale: 0.45
      })
    },
    enableSaveButton() {
      this.loading = false
      this.$vs.loading.close("#saveButton > .con-vs-loading")
    },
    addUpdateData() {
      const obj = {
        id: this.dataId,
        id_owner: this.dataIdOwner,
        name: this.dataName,
        tiptap_commission: this.dataTiptapCommission
      }

      if (!this.dataId)
        delete obj.id

      let action = ((this.dataId !== null && this.dataId >= 0) ? "updateSchool" : "addSchool");

      this.$store.dispatch(`dataListInstitutions/${action}`, obj)
        .then(response => {
          if(!response.status) {
            throw new Error(response.msg);
          }
          this.enableSaveButton()
          this.showAddSuccess()
          this.$emit('fetchSchools');
          this.$emit('closeSidebar');
          this.initValues();
        })
        .catch(error => {
          this.showError(error)
          this.enableSaveButton()
        })
    },
    updateOwner(event) {
      this.dataOwner = event.value
    },
    getOwnerChoices() {
      if(this.owner_choices && this.owner_choices.length) return

      const owners = this.$store.getters['userManagement/getOwners'];
      owners.forEach(element => {
        this.owner_choices.push({label: element.full_name, value: element.id})
      });
    },
    showError(msg) {
      this.$vs.notify({
        color: 'danger',
        title: 'Error',
        text: msg || 'No se pudo completar el proceso.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-alert-circle',
      })
    },
    showAddSuccess() {
      this.$vs.notify({
        color: 'success',
        title: 'Escuela',
        text: 'Se han guardado los cambios.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-check',
      })
    },
    updateCurrImg(input) {
      this.image_loaded = false;
      this.image_error = '';
      if (input.target.files && input.target.files[0]) {
        this.product_image.size = input.target.files[0].size;
        var reader = new FileReader()
        reader.onload = e => {
          let img = new Image();
          img.onload = () => {
            this.product_image.width = img.width;
            this.product_image.height = img.height;
            // console.log(this.product_image.size);
            if (this.product_image.width != this.product_image.height || this.product_image.width > 300 ) {
              this.image_error = 'La imágen debe ser cuadrada (misma altura y ancho) con un máximo de 300px';
              // console.log(this.image_error);
            } else {
              if (this.product_image.size > 100000){
                this.image_error = 'La imágen excede el tamaño máximo (100 KB).';
              } else {
                this.dataImg = e.target.result
                this.image_loaded = true;
              }
            }
          }
          img.src = e.target.result;
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    checkLength(value) {
      if(value < 0 || value == '')
        value = 0

      this.dataTiptapCommission = (typeof value == 'string') ? parseFloat(value).toFixed(3) : value.toFixed(3);
    },
  },
  components: {
    VuePerfectScrollbar,
    'v-select': vSelect,
 /*    vSelect, */
  },
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
    p {
      margin-bottom: 2rem;
      > span {
        font-size: 0.9rem;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
