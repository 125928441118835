<template>
  <div id="data-list-thumb-view" class="data-list-container">
    <data-view-sidebar
      :isSidebarActive="addNewDataSidebar"
      @closeSidebar="toggleDataSidebar"
      @fetchSchools="fetchSchools"
      :data="sidebarData"
      />
    <h2 class="text-center">Escuelas</h2>
    <vs-table ref="table" v-model="selected" pagination :max-items="itemsPerPage" search :data="institutions">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <vs-button class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary" color="primary" type="border" icon-pack="feather" icon="icon-plus" @click="addNewData">Agregar Nuevo</vs-button>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ institutions.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : institutions.length }} de {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=50">
              <span>50</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th>Imagen</vs-th>
        <vs-th sort-key="id">Id</vs-th>
        <vs-th sort-key="name">Escuela</vs-th>
        <vs-th sort-key="tiptap_comission" style="max-width:120px">Comisión Servicios (Pago Único)</vs-th>
        <vs-th sort-key="admin">Administrador</vs-th>
        <vs-th sort-key="admin">Servicios Adheridos</vs-th>
        <vs-th sort-key="status">Estado</vs-th>
        <vs-th>Acciones</vs-th>
      </template>

        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" @click="editData(tr)">

              <vs-td class="img-container">
                <img v-if="tr.image" :src="imgPath + tr.image" class="product-img" />
                <div v-else class="mr-8 rounded h-24 w-24 primary flex items-center flex-col justify-center" style="background-color: #f5f5f5"><feather-icon icon="ImageIcon" style="color:lightgray"></feather-icon></div>
              </vs-td>

              <vs-td>
                <p>{{ tr.id }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name truncate">{{ tr.name }}</p>
              </vs-td>

               <vs-td>
                <p class="product-name truncate">{{ tr.tiptap_comission }} %</p>
              </vs-td>

              <vs-td>
                <p class="product-name truncate">{{ tr.name_owner || '' }}</p>
              </vs-td>

              <vs-td>
                <vs-chip v-if="tr.gop_asociated" color="primary" class="product-order-status">Garantía de Pago</vs-chip>
              </vs-td>

              <vs-td>
                <vs-chip :color="getOrderStatusColor(tr.status)" class="product-order-status">{{ tr.status === 0 ? "Inactivo" : "Activo" }}</vs-chip>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <vx-tooltip text="Editar" class="float-left">
                  <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr)" />
                </vx-tooltip>
                <vx-tooltip :text="tr.status == 1 ? 'Eliminar' : 'Activar'" class="float-left">
                  <feather-icon :icon="tr.status == 1 ? 'TrashIcon' : 'RefreshCwIcon'" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr)" />
                </vx-tooltip>
                <vx-tooltip text="Ir a Servicios" class="float-left">
                  <feather-icon icon="ToolIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="openServices" />
                </vx-tooltip>
                <vx-tooltip text="Garantía de Pago" class="float-left">
                  <feather-icon icon="SlidersIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="servicesConfig(tr.id)" />
                </vx-tooltip>
              </vs-td>

            </vs-tr>
          </tbody>
        </template>
    </vs-table>
  </div>
</template>

<script>
import DataViewSidebar from '../Datalist/DataViewSidebarSchools.vue'
import moduleDataListInstitutions from "@/store/data-list-institutions/moduleDataList.js"
import moduleUserManagement from "@/store/user-management/moduleUserManagement.js"

export default {
  components: {
    DataViewSidebar
  },
  data() {
    return {
      institutions: [],
      selected: [],
      itemsPerPage: 10,
      isMounted: false,
      imgPath: this.$baseUrlImg,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.institutions.length
    }
  },
  methods: {
    openServices() {
      this.$router.push('/services')
    },
    addNewData() {
      this.$router.push({name: 'school-add'})
      // this.sidebarData = {}
      // this.toggleDataSidebar(true)
    },
    deleteData(tr) {
      const obj = {
              "school": {
                "id_school": tr.id,
                "name": tr.name,
                "tiptap_comission": tr.tiptap_comission,
                "id_school_type": 1,
                "address": tr.address || '',
                "city": tr.city || '',
                "zip_code": tr.zip_code || '',
                "image": tr.image ? tr.image : '',
                "status": (tr.status == 1) ? 0 : 1
              },
              "bank_account": {
                "id_bank": tr.bank.id,
                "bank_name": tr.bank.bank_name || '',
                "account_type": tr.bank.account_type || 0,
                "account_name": tr.bank.account_name || '',
                "account_number": tr.bank.account_number || ''
              },
              "collectors": {
                "id_account": tr.collectors.map(a => a.id_account),
                "id_school": tr.collectors[0].id_school
              }
          }

      this.$store.dispatch("dataListInstitutions/updateSchool", obj)
      .then(response => {
        if(!response.data.status)
          throw new Error(response.data.msg)

        this.showAddSuccess()
      })
      .catch(error => { this.showError(error) })

    },
    editData(data) {
      this.$router.push({name: 'school-edit', params: { idSchool: data.id }});
      // this.sidebarData = data
      // this.toggleDataSidebar(true)
    },
    servicesConfig(idSchool) {
      this.$router.push("/institution/school/services/" + idSchool).catch((error) => {console.log(error)})
    },
    getOrderStatusColor(status) {
      if(status === 0) return "danger"
      if(status === 1) return "success"
      //if(status == 'canceled') return "danger"
      return "primary"
    },
    getPopularityColor(num) {
      if(num > 90) return "success"
      if(num >70) return "primary"
      if(num >= 50) return "warning"
      if(num < 50) return "danger"
      return "primary"
    },
    toggleDataSidebar(val=false) {
      this.addNewDataSidebar = val
    },
    showError(msg) {
      this.$vs.notify({
        color: 'danger',
        title: 'Error',
        text: msg || 'No se pudo completar el proceso.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-alert-circle',
      })
    },
    showAddSuccess() {
      this.$vs.notify({
        color: 'success',
        title: 'Escuela',
        text: 'Se han guardado los cambios.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-check',
      })
    },
    fetchSchools() {
      this.$store.dispatch("dataListInstitutions/getSchools")
          .then(() => {
            this.institutions = this.$store.state.dataListInstitutions.schools
          })
    }
  },
  beforeMount(){
    if(!moduleDataListInstitutions.isRegistered) {
      this.$store.registerModule('dataListInstitutions', moduleDataListInstitutions)
      moduleDataListInstitutions.isRegistered = true
    }
    if(!moduleUserManagement.isRegistered) {
      this.$store.registerModule('userManagement', moduleUserManagement)
      moduleUserManagement.isRegistered = true
    }

    this.fetchSchools()
  },
  mounted() {
    this.isMounted = true;
  }
}
</script>

<style lang="scss">
  #data-list-thumb-view {
    .vs-con-table {

      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap-reverse;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search{
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            &+i {
              left: 1rem;
            }

            &:focus+i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;


        tr{
            box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
            td{
              padding: 10px;
              &:first-child{
                border-top-left-radius: .5rem;
                border-bottom-left-radius: .5rem;
              }
              &:last-child{
                border-top-right-radius: .5rem;
                border-bottom-right-radius: .5rem;
              }
              &.img-container {
                // width: 1rem;
                // background: #fff;

                span {
                  display: flex;
                  justify-content: flex-start;
                }

                .product-img {
                  height: 110px;
                }
              }
              .product-stock-min {
                max-width: 6rem;
                text-align: center;
              }
            }
            td.td-check{
              padding: 20px !important;
            }
        }
      }

      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
</style>
